<script>
export default {
  name: 'GlueSmallSpecial',
  props: {
    devise: { type: Object },
    special: {
      type: Object,
      required: true,
    },
    track: {
      type: String,
      default: null,
    },
    imageOnTop: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'glue',
    },
    location: {
      type: String,
      default: 'inPage',
    },
    showBookNow: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    titleHeight() {
      if (this.$voix.breakpoint.value !== 'default' && this.$voix.breakpoint.value !== 'sm')
        return { height: '130px' }

      return {}
    },
    copyHeight() {
      if (this.$voix.breakpoint.value !== 'default' && this.$voix.breakpoint.value !== 'sm')
        return { height: '360px' }

      return {}
    },
    descriptionHeight() {
      if (this.$voix.breakpoint.value !== 'default' && this.$voix.breakpoint.value !== 'sm')
        return { height: '90px' }

      return {}
    },
    learnMoreLink() {
      if (this.special.page?.permalink)
        return this.special.page.permalink

      return null
    },
  },
  methods: {
    goToSpecial() {
      location.href = this.special.page?.permalink
    },

    pushToDataLayer() {
      if (window.dataLayer)
        window.dataLayer.push({ offerName: this.special.title })
    },
  },
}
</script>

<template>
  <div
    v-if="special.id"
    class="flex justify-between"
    :class="{ 'flex-col': !imageOnTop, 'flex-col-reverse': imageOnTop }"
  >
    <div class="small-special-card bg-white pt-4 px-8 pb-8 w-full flex-grow" :style="copyHeight">
      <div class="mb-6">
        <div v-if="location === 'slideOut'" :style="titleHeight">
          <!-- <div
            class="font-light text-red-500 italic mb-4 text-xl tracking-wider text-18"
            v-html="special.travel_date_range"
          ></div> -->

          <div
            class="font-light text-red-500 italic font-serif mb-1 text-xl tracking-wider"
          >
            <span>{{ special.title }}</span>
          </div>
          <div
            v-if="special.featured"
            class="uppercase font-sans font-light leading-tight text-2xl mb-4 tracking-widest"
            :class="{
              'text-gray-800 ': mode === 'glue',
              'text-glueblue-600': mode === 'summer',
            }"
          >
            Featured Offer
          </div>
        </div>
        <div v-else :style="titleHeight">
          <div
            class="font-light text-red-500 italic font-serif mb-1 text-xl tracking-wider"
          >
            <span v-if="special.featured">Featured Offer</span>
            <span v-else>&nbsp;<!-- spacer --></span>
          </div>
          <div
            class="uppercase font-sans font-light leading-tight text-2xl mb-4 tracking-widest special-title"
            :class="{
              'text-gray-800 ': mode === 'glue',
              'text-glueblue-600': mode === 'summer',
            }"
          >
            {{ special.title }}
          </div>
        </div>
        <div
          class="font-light text-[17px] mb-6 special-description"
          :class="{
            'text-zinc-600 ': mode === 'glue',
            'text-glueblue-600': mode === 'summer',
          }"
          :style="descriptionHeight"
          v-html="special.short_description"
        />
      </div>
      <div class="h-16 flex flex-col md:flex-row justify-start md:items-center space-y-3 md:space-y-0 md:space-x-6">
        <BookingBookNow
          v-if="special.show_book_now_btn && showBookNow"
          :dropdown="false"
          :location="`Special - ${special.title}`"
          :special="special.id"
          :track="track"
          disableable
          @click.native="pushToDataLayer"
        >
          <template #default="slotProps">
            <button
              class="btn-xs btn-ghost"
            >
              Book Now
            </button>
          </template>
        </BookingBookNow>

        <a
          v-for="(link, index) in special.links"
          :key="index"
          :href="link.href"
          class="flex items-center group btn btn-xs btn-text text-glueblue-500 space-x-1 special-learn-more"
        >
          <span>{{ link.text }}</span>
          <Icon name="heroicons:arrow-long-right-solid" class="-translate-y-0.5 group-hover:translate-x-2 duration-300 inline-block w-4 h-4" />
        </a>
      </div>
    </div>
    <div
      v-if="special?.small_image"
      class="bg-cover bg-center"
      style="height: 225px"
      :style="{
        backgroundImage: `url(${special.small_image})`,
      }"
    />
    <div
      v-if="!special?.small_image && special?.main_image"
      class="bg-cover bg-center"
      style="height: 225px"
      :style="{
        backgroundImage: `url(${special.main_image})`,
      }"
    />
  </div>
</template>
